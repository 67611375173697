import { bootstrapAlert } from "./global/alert";
import { showDialog } from "./global/dialog";
import { isMob } from "./global/util";

function logToConsoleDump(text){
    let element = document.createElement('p');
    element.innerHTML = text;
    let dump = document.getElementById('console-dump');
    dump.appendChild(element);

    let logElement = document.getElementById('console-log');
    if (logElement) {
        logElement.innerHTML = dump.innerHTML;
        logElement.scrollTop = logElement.scrollHeight;
    } 
}

function showConsoleDialog(){
    showDialog({
        content: `
            <textarea data-console-command rows="3" placeholder="Команда..." class="mb-1 form-control"
                autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
            ></textarea>
            <div class="d-flex align-items-center gap-1">
                <button data-console-send class="btn btn-tum-dark-red">Запустить</button>
                <a href="javascript:void(0)" data-console-previous class="fas fa-chevron-circle-up fa-lg"></a>
                <a href="javascript:void(0)" data-console-next class="fas fa-chevron-circle-down fa-lg"></a>
                <a href="javascript:void(0)" data-console-clear class="fas fa-times fa-lg"></a>
            </div>
            <hr>
            Вывод:
            <pre id="console-log"></pre>
        `,
        title: 'Консоль',
        showFooter: false,
        onShown: () => {
            let history = localStorage.getItem('console-history');
            history = history ? JSON.parse(history) : [];

            let historyPosition = history.length;
            let log = document.getElementById('console-log');
            log.innerHTML = document.getElementById('console-dump').innerHTML;
            log.scrollTop = log.scrollHeight;

            document.querySelector('[data-console-send]').addEventListener('click', () => {
                let text = document.querySelector('[data-console-command]');
                if (!text) return;

                try {
                    eval(text.value);
                } catch (e) {
                    console.log(e);
                }

                let history = localStorage.getItem('console-history');
                history = history ? JSON.parse(history) : [];
                if (history[history.length - 1] != text.value) history.push(text.value);
                historyPosition = history.length;
                localStorage.setItem('console-history', JSON.stringify(history));

                text.value = '';
            });

            document.querySelector('[data-console-previous]').addEventListener('click', () => {
                if (!historyPosition) return;
                let history = localStorage.getItem('console-history');
                if (history) {
                    try {
                        history = JSON.parse(history);
                        historyPosition -= 1;
                        document.querySelector('[data-console-command]').value = history[historyPosition] || '';
                    } catch {}
                }
            });

            document.querySelector('[data-console-next]').addEventListener('click', () => {
                let history = localStorage.getItem('console-history');
                if (history) {
                    try {
                        history = JSON.parse(history);
                        if (historyPosition < history.length - 1) {
                            historyPosition += 1;
                            document.querySelector('[data-console-command]').value = history[historyPosition] || '';
                        }
                    } catch {}
                }
            });

            document.querySelector('[data-console-clear]').addEventListener('click', () => {
                localStorage.removeItem('console-history');
                bootstrapAlert('История очищена');
            });
        },
        pinToBottom: isMob()
    });
}

function initConsoleHandler() {
    if (!document.getElementById('show-console')) return;

    if (!document.getElementById('console-dump')) {
        let element = document.createElement('div');
        element.id = 'console-dump';
        element.classList.add('d-none');
        document.body.appendChild(element);
    }

    // define a new console
    let console = (function(oldCons){
        return {
            log: function(text){
                oldCons.log(text);
                logToConsoleDump(text);
            },
            info: function (text) {
                oldCons.info(text);
                logToConsoleDump(text);
            },
            warn: function (text) {
                oldCons.warn(text);
                logToConsoleDump(text);
            },
            error: function (text) {
                oldCons.error(text);
                logToConsoleDump(text);
            },
            debug: function(text){
                oldCons.debug(text);
                logToConsoleDump(text);
            }
        };
    }(window.console));

    //Then redefine the old console
    window.console = console;

    document.getElementById('show-console').addEventListener('click', showConsoleDialog);
}

export { initConsoleHandler };
