﻿import * as $ from "jquery";
import "../css/modules/tu.m/CookiesNotice.css";
import { getCookie, setCookie } from "./global/cookie";
import { addEventDelegate } from "./global/dom";

var cookNot = {
	options: {
		cookiePrefix: 'agreement-cookies-accepted',
	},
	init: function (options) {
		cookNot.options = $.extend(cookNot.options, options);

		var cookData = getCookie(cookNot.options.cookiePrefix);

		if (cookData && cookData != 'true') {
			$('.tum-agreement-cookies-window').removeClass('d-none');
		}

		addEventDelegate(document, 'click', '.tum-agreement-cookie-ok', () => {
			setCookie(cookNot.options.cookiePrefix, "true", 90);
			$('.tum-agreement-cookies-window').addClass('d-none');
		});
	}
};

export default cookNot;