/* global tumarket */

import * as $ from "jquery";
import "bootstrap/js/dist/modal";
import { getCookie, setCookie } from "../global/cookie";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "../global/templates";

//из-за того, что код18+ может вызываться из разных мест и иногда одновременно, нужно проверять, чтобы не запустить лишний раз
//и не было конфликтов в логике

//запущена ли сейчас проверка на наличие взрослых товаров
let checkRunning = false;

//показывается ли сейчас модалка с предупреждением
let modalShown = false;

function isCtuIgnoredAdulthood(ctuIDs) {
    let ignoreCheck = false;
    let url = location.pathname;
    if (url.indexOf('/category/') != -1 || url.indexOf('/offers') != -1) {
        if (url.indexOf('/category/') != -1) {
            $('._adult-product').parent().show();
            return true;
        }

        tumarket.adulthoodIDs = tumarket.adulthoodIDs || [];
        //собираем кту, которые в разоре и по аяксу
        var initCtuIDs = $('.b-firm-block-header.open, .b-firm-block-header.panelClose').map(function () {
            return Number($(this).attr('data-itemid'));
        });

        ctuIDs = ctuIDs || []; //кту по аяксу
        $.each(initCtuIDs, function (i, el) {
            if (ctuIDs.indexOf(el) == -1)
                ctuIDs.push(el);
        });

        //кту со страницы, которые относятся к запрету
        let ktuAdultCount = ctuIDs.filter(el => tumarket.adulthoodIDs.indexOf(el) > -1).length;

        //кту со страницы, которые без запрета
        let ktuNotAdult = [];
        $.each(ctuIDs, function (i, el) {
            if (ktuNotAdult.indexOf(el) == -1 && tumarket.adulthoodIDs.indexOf(el) == -1) {
                ktuNotAdult.push(el);
            }
        });

        /*
         * Если в компании 2 категории и они 18+, чекбокс так же не берется во внимание.  
         * А вот если у компании 3 категории(2 - 18 +, а одна разрешенная,)
         * то тогда чекбокс берем во внимание и карточки скрываются.
        */
        ignoreCheck = (ktuAdultCount == ktuNotAdult.length)	|| !ktuNotAdult.length;
        //показываем ТУ в КТУ которые выведены без аякса
        if (ignoreCheck) $('._adult-product').parent().show();
    }
    
    return ignoreCheck;
}

function toggleAdultProducts(hide, ignoreCheck) {
    if (ignoreCheck) return;

    if (hide) {
        $('._adult-product:not(.tu-adult-product)').parent().hide();
        //для большой карточки ТУ запрещаем добавить в корзину и заказать
        if ($('.addToCartBlock').length) {
            $('.addToCartBlock').addClass('adult-product-tu');
        }
        $('.restrict-msg').remove();
    } else {
        $('._adult-product').parent().show();
        $('.restrict-msg').remove();
    }

    let icon = `
        <span class="fa-stack fa-plus18-wrapper">
            <i class="fa fa-circle fa-stack-1x"></i>
            <span class="fa fa-stack-1x fa-inverse fa-plus18"></span>
            <span class="fa-stack-1x fa-plus18-plus">+</span>
        </span>`;

    let msg = `
        <div class="container cursor-pointer px-0 restrict-msg tum-dark-red mb-2">
            ${icon} Ограничение по возрасту
        </div>`;

    $('.b-footer-menu').prepend(msg);
}

function renderAdultWarning(data){
    $('.adulthood-modal').remove();

    return loadPrecompiledTemplates(['adultContentWarning']).then(() => {
        var html = getPrecompiledTemplate('adultContentWarning')(data);
        $('body').append(html);
    });
}

function showAdultWarning(important, ignoreCheck) {
    if (modalShown || checkRunning) return;

    let pageHasAdultProducts = !!$('._adult-product, ._adult-product_shown').length;
    if (!pageHasAdultProducts) return;

    checkRunning = true;

    let cookiesExist = getCookie('adult-hideAdult') !== '' && getCookie('adult-confirmedOver18') !== '';
    let hideAdultProducts = getCookie('adult-hideAdult') == "true";
    let userConfirmedAdult = getCookie('adult-confirmedOver18') == "true";
    
    if (cookiesExist && !important) {
        if (userConfirmedAdult) {
            $('._adult-product').removeClass('_adult-product').addClass('_adult-product_shown');
            $('.addToCartBlock').removeClass('adult-product-tu').addClass('adult-product-tu_shown');
        }

        toggleAdultProducts(hideAdultProducts, ignoreCheck);
        checkRunning = false;
        return;
    }

    if (!important) {
        if (cookiesExist) {
            checkRunning = false;
            return;
        } 

        var adultProductsExist = $('._adult-product').not('#visitedBlock ._adult_product').length > 0;
        if (!adultProductsExist) { 
            checkRunning = false;
            return;
        }
    }

    modalShown = true;
    renderAdultWarning({ hideAdult: hideAdultProducts, userIsAdult: (cookiesExist && userConfirmedAdult) || false, cookiesExist }).then(() => {
        let $modal = $('.adulthood-modal');

        $('.adultWarning-confirmOver18').on('click', () => {
            setCookie('adult-confirmedOver18', true);
            setCookie('adult-hideAdult', false);
            
            $modal.modal('hide');
            toggleAdultProducts(false, ignoreCheck);

            $('._adult-product').removeClass('_adult-product').addClass('_adult-product_shown');
            $('.addToCartBlock').removeClass('adult-product-tu').addClass('adult-product-tu_shown');
        });

        $('.adultWarning-close').on('click', () => {
            let hideAdult = $('.adultWarning-hideAdult').prop('checked');
            setCookie('adult-confirmedOver18', false);
            setCookie('adult-hideAdult', hideAdult);
            
            let isIgnore = isCtuIgnoredAdulthood();
            toggleAdultProducts(hideAdult, isIgnore);

            $('._adult-product_shown').removeClass('_adult-product_shown').addClass('_adult-product');
            $('.addToCartBlock_shown').removeClass('adult-product-tu_shown').addClass('adult-product-tu');
        });

        $modal.on('hidden.bs.modal', () => modalShown = false);

        $modal.modal('show');
    });

    checkRunning = false;
}

export { showAdultWarning, isCtuIgnoredAdulthood };
