import * as $ from "jquery";
import { getCookie, setCookie } from "./global/cookie";
import { showDialog, closeDialog } from "./global/dialog";
import { addEventDelegate } from "./global/dom";

var editMode = {
	options: {
		cookieKey: 'isEditView',
		interval: null,
		idleTimer: null,
		idleState: false, // состояние отсутствия
		idleWait: 4*60*60*1000, // интервал запуска счетчика 4ч
		editLimitationsMsg: ""
	},
	init: function (options) {
		editMode.options = $.extend(editMode.options, options);
		this.options.editLimitationsMsg = $(".tum-editLimitationsMsg").html();

		$(document).on('mousemove keydown scroll', function () {
			if (getCookie(editMode.options.cookieKey)) {
				clearTimeout(editMode.options.idleTimer); // отменяем прежний временной отрезок
				if (editMode.options.idleState == true) {
					// Действия на возвращение пользователя
					$(".time-edit-unset").text('');
					$(".time-edit-desc").addClass('hide');
					clearTimeout(editMode.options.interval);
					if ($('.isTumViewEditTime').lenght > 0) {
						closeDialog();
					}
				}

				editMode.options.idleState = false;
				editMode.options.idleTimer = setTimeout(function () {
					// Действия на отсутствие пользователя
					if (getCookie(editMode.options.cookieKey)) {
						$(".time-edit-desc").removeClass('hide');
						if ($('.isTumViewEditTime').length < 1) {

							var isEditViewActive = getCookie("isEditViewActive");
							if (isEditViewActive == "1"){
								setCookie(editMode.options.cookieKey, 1, 365);
								$('#asModal .isTumViewEditTime').remove(); 
								return;
							}
							
							var text = '<div class="isTumViewEditTime">' +
								'<a href="javascript:void(0)" data-dismiss="modal" class="btn text-white tum-bg-dark-red tum-border-dark-red tum-edit-set">Продолжить</a> ' +
								'<a href="#" class="btn text-white tum-bg-dark-red tum-border-dark-red isTumViewEditTime tum-edit-unset">Завершить</a><br />' +
								'<span>У вас несколько минут до автозавершения, таймер включен</span>' +
								'</div>';
							var header = '<span class="h4 modal-title"></span>';
							header += '<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>';
							$('#asModal .modal-header').html(header);

							showDialog({
								title: 'Режим редактирования',
								content: text,
								onSubmit: () => {
									setCookie(editMode.options.cookieKey, 1, 365);
									closeDialog();
									$('#asModal .isTumViewEditTime').remove(); 
								},
								onShown: () => {
									$('#asModal .modal-dialog').addClass('modal-dialog-centered');
									$("#asModal .modal-footer").empty().append($("#asModal .tum-edit-set")).append($(".isTumViewEditTime.tum-edit-unset"));
								},
								onClosed: () => $('#asModal .isTumViewEditTime').remove()
							});
						}
						editMode.countDown();
					}
					editMode.options.idleState = true; 
				}, editMode.options.idleWait);
			}
		});

		addEventDelegate(document, 'click', '.isEditView', (element, e) => {
			e.preventDefault();
			let enable = !$(element).hasClass('tum-edit-unset');
			let redirectUrl = $(element).hasClass('turn') ? $(element).attr('data-url') : null;
			editMode.showRolesModal(enable, redirectUrl);
		});

		addEventDelegate(document, 'click', '#logoutForm', () => {
			setCookie(editMode.options.cookieKey, '', -1);
		});

		addEventDelegate(document, 'click', '.isTumViewEditTime', (element, e) => {
			e.preventDefault();
			editMode.toggleEditView(element);
			closeDialog();
			location.reload();
		});

		addEventDelegate(document, 'click', '.toggleEditMode', () => {
			editMode.toggleEditView();
			location.reload();
		});
	},
	showRolesModal: function (enable, redirect) {
		if (!enable) {
			setCookie(editMode.options.cookieKey, '', -1);
			location.reload();
			return;
		}

		var confirm = function () {
			editMode.toggleEditView(enable);
			location.reload();
		};

		var $msg = $(editMode.options.editLimitationsMsg);
		var txt = $('.isEditView.tum-edit-set + .tum-unauthorized-hint').attr('data-content');
		if ($msg.hasClass("showModal")) {
			showDialog({ title: "Права на редактирование", content: txt, submitButtonText: "Продолжить" })
			$("#asModal .btn-primary").remove();
			$("#asModal .tum-editLimitationsMsg i").remove();
			$("#asModal").on("hide.bs.modal", confirm);
		} else {
			editMode.toggleEditView(enable);
			if (redirect) {
				location.href = redirect;
				return;
			}
			location.reload();
		}
	},
	toggleEditView: function (enable) {
		if (enable) {
			setCookie(editMode.options.cookieKey, 1, 365);
		} else {
			setCookie(editMode.options.cookieKey, '', -1);
		}
	},
	enabled: function(){
		return getCookie(editMode.options.cookieKey) == 1;
	},
	countDown: function () {
		var date = new Date();
		var Day = date.getDate();
		var Year = date.getFullYear();
		var Month = date.getMonth();
		var Hours = date.getHours();
		var Minutes = date.getMinutes();
		var Seconds = date.getSeconds();
		var Milliseconds = date.getMilliseconds();
		var countDownDate = new Date(Year, Month, Day, Hours, Minutes+15, Seconds, Milliseconds).getTime();
		editMode.countDownInterval(countDownDate);
	},
	countDownInterval: function (dateTime) {
		editMode.options.interval = setInterval(function () {
			var time = new Date().getTime();
			var timeLeft = dateTime - time;
			if (timeLeft <= 0) {
				setCookie(editMode.options.cookieKey, '', -1);
				clearTimeout(editMode.options.interval);
				location.reload();
			}
			var minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
			if (minutes.toString().length == 1) {
				minutes = '0' + minutes.toString();
			}

			var seconds = Math.floor((((timeLeft % (60 * 60 * 1000 * 24)) % (60 * 60 * 1000)) % (60 * 1000)) / 1000 * 1);
			if (seconds.toString().length == 1) {
				seconds = '0' + seconds.toString();
			}

			var milisecons = Math.floor((timeLeft % 1000) / 10);
			if (milisecons.toString().length == 1) {
				milisecons = '0' + milisecons.toString();
			}

			var tmrStr = minutes + ':' + seconds + ':' + milisecons;
			$(".time-edit-unset").text(tmrStr);

		}, 10);
	}
};

export { editMode };