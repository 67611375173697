import * as $ from "jquery";
import tumGlobal from "./global";
import { addEventDelegate } from "./global/dom";

var backToTop = {
	init: function () {
		backToTop.PageUp($(".tum-to-bottom"));

		addEventDelegate(document, 'click', '.tum-to-bottom', () => {
			backToTop.hideActionModal(false);
			return false;
		});

		addEventDelegate(document, 'click', '.tum-to-top', () => {
			backToTop.hideActionModal(true);
			$('body,html').animate({
				scrollTop: 0
			}, 1000);
			return false;
		});

		addEventDelegate(document, 'click', 'body', () => {
			if (!$('.tum-action-container .modal-actions').hasClass('d-none')) {
				backToTop.hideActionModal(true);
			}
		});
	},
	PageUp: function (btn) {
		var limit = $(window).height();

		$(window).on('scroll', function () {
			if ($(this).scrollTop() > limit) {
				if (tumGlobal.isMob() && $('.header-floating.show').length) {
					$(btn).addClass('mob-floating-header-shown')
				}

				$(btn).fadeIn();
			} else {
				$(btn).fadeOut();
			}
			if (!$('.tum-action-container .modal-actions').hasClass('d-none')) {
				backToTop.hideActionModal(true);
			}
		});
	},
	hideActionModal: function (hide) {
		$('.tum-action-container .modal-actions').toggleClass('d-none', hide);
	}
};

export { backToTop };