﻿import * as $ from "jquery";

import { addEventDelegate } from "../global/dom";

var contentMistakes = {
	init: function () {
		$(document).on('mouseup touchend', 'body', function(e) {
			if ($(e.target).hasClass("mistakeRef")) {
				return;
			}
			if ($(e.target).hasClass('hideErrorObject')) {
				return;
			}
			if ($(e.target).hasClass('tum-sendErrorIcon')) {
				return;
			}
			contentMistakes.showErrorObject();
		});

		addEventDelegate(document, 'click', 'body', (element, e) => {
			var el = $(e.target).parent();
			if (!contentMistakes.getSelectionText() && !$(el).hasClass('tum-errorObject')) {
				contentMistakes.removePopovers();
				$('.tum-error-container').find('.msg').addClass('d-none');
			}
		});
		
		addEventDelegate(document, 'click', '.hideErrorObject, .hideTumErrorObject', (element, e) => {
			e.preventDefault();
			$(element).closest(".tum-errorObject").attr("hidden", "");
			$(element).closest('.msg').addClass('d-none');
			contentMistakes.unselectAll();
		});
		
		addEventDelegate(document, 'click', '.tum-sendErrorIcon', (element, e) => {
			e.preventDefault();
			$(element).closest('.tum-error-container').find('.msg').removeClass('d-none');
			//contentMistakes.removePopovers();
		});
	},
	unselectAll: function () {
		if (document.selection && document.selection.empty) {
			document.selection.empty();
		}
		else if (window.getSelection) {
			var sel = window.getSelection();
			if (sel && sel.removeAllRanges)
				sel.removeAllRanges();
		}
	},
	getSelectionParentElement: function () {
		var parentEl = null, sel;
		if (window.getSelection) {
			sel = window.getSelection();
			if (sel.rangeCount) {
				parentEl = sel.getRangeAt(0).commonAncestorContainer;
				if (parentEl.nodeType != 1) {
					parentEl = parentEl.parentNode;
				}
			}
		} else if ((sel = document.selection) && sel.type != "Control") {
			parentEl = sel.createRange().parentElement();
		}
		return parentEl;
	},
	getSelectionText: function () {
		var text = "";
		var activeEl = document.activeElement;
		var activeElTagName = activeEl ? activeEl.tagName.toLowerCase() : null;
		if (
			(activeElTagName == "button") ||(activeElTagName == "textarea") || (activeElTagName == "input" &&
				/^(?:text|search|password|tel|url)$/i.test(activeEl.type)) &&
			(typeof activeEl.selectionStart == "number")
		) {
			text = '';//activeEl.value.slice(activeEl.selectionStart, activeEl.selectionEnd);
		} else if (window.getSelection) {
			text = window.getSelection().toString();
		}
		return text;
	},
	_getItemID: function($elem){
		var res = $elem.attr('data-error-itemid');
		if (!res && $elem.hasClass('b-product')) {
			res = $elem.attr('data-stat-productid') || $elem.attr('data-stat-firmid');
		}
		return res;
	},
	showErrorObject: function () { //x,y
		if (contentMistakes.getSelectionText()) {
			var selectionElement = contentMistakes.getSelectionParentElement();
			if ($(selectionElement).is("input, textarea")) {
				return;
			}
			if (selectionElement) {
				var mainParent = $(selectionElement).closest('[data-error-object]');
				var itemID = this._getItemID(mainParent);
				var errorObject = mainParent;
				if (errorObject) {
					var s = `<div class='error-selected-text d-flex pr-1'><span class='pl-1' style='color:#959592;'>выделено:</span> <div class='tum-error-selected'>${contentMistakes.getSelectionText()}</div></div>`;
					var params = '?text=' + contentMistakes.getSelectionText() + '&errorObject=' + mainParent.attr('data-error-object') + '&returnUrl=' + location.href + (itemID ? "&itemID=" + itemID : '');
					s += `<div class='text-white text-center mt-1'>Ошибка или Предложение?</div><div class='text-center'><a id='mistakeRef'  class='mistakeRef font-weight-bold' href='/sendcontentmistakes${params}' target="_blank">Сообщите нам</a><span class='mx-2 text-white'>|</span><a rel="nofollow" href="#" class="hideErrorObject">Закрыть</a></div>`;
					$('.tum-errorObject .msg').html(s);
					$('.tum-errorObject').removeAttr('hidden');
				}
			}
		} 
	},
	removePopovers: function () {
		if ($('.tum-errorObject').length > 0) {
			$('.tum-errorObject').each(function () {
				$(this).attr("hidden", "");
			});
		}
		
	}
};

export default contentMistakes;