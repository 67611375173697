import { getCookie } from "./global/cookie";
import { addEventDelegate, appendHtml } from "./global/dom";
import { ajaxSend } from "./global/fetch";
import { initTuPopover } from "./global/popover";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "./global/templates";
import { initIntersectionObserver } from "./intersectionObserver";
import tu_slider from "./slider";
import { tumStats } from "./statistics";
import { showWelcomeDialog } from "./welcome-dialog";

function getBanners(bannerIds, contextCode) {
    return ajaxSend({ url: '/requests/getBanners', data: { bannerIds, contextCode } });
}

function initBannerSliders() {
    for (let slider of document.querySelectorAll('.banners-slider')) {
        let initialSize = Number(slider.getAttribute('data-initial-size'));
        let totalIds = slider.getAttribute('data-ids').split(',');

        tu_slider.create({
            container: slider,
            totalCount: totalIds.length,
            getItems: callback => {
                let bannerIds = totalIds.slice(initialSize);
                loadPrecompiledTemplates(['banners-slider-item']).then(() => {
                    return getBanners(bannerIds);
                }).then(data => {
                    if (data.result) {
                        let template = getPrecompiledTemplate('banners-slider-item');
                        let newItems = data.value?.banners?.map(e => template(e)).join("");

                        if (!newItems) return;

                        appendHtml(slider.querySelector('.slider-list'), newItems);
                        callback();
                        initTuPopover();
                        initIntersectionObserver();
                    }
                });
            },
            mobLoadMoreChevronDirection: 'right',
            autoCycling: true
        });
    }

    addEventDelegate(document, 'click', '.banner-expand', element => {
        let id = Number(element.closest('.banner-slide').getAttribute('data-bannerid'));

        getBanners([id], "for_popup").then(data => {
            showWelcomeDialog(true, data.value.banners[0]);
        });
    });
}

function showRandomBannerAsPopup(timerContext){
    let popupIds = [];
    for (let slider of document.querySelectorAll('.banners-slider')) {
        popupIds = popupIds.concat(slider.getAttribute('data-popup-ids').split(',').filter(e => e).map(Number));
    }

    let availablePopup = popupIds.find(e => !getCookie(`disableBanner2_${e}`));
    if (!availablePopup) return;

    getBanners([availablePopup], "for_popup").then(data => {
        let banner = data.value.banners[0];
        showWelcomeDialog(false, banner, timerContext);

        tumStats.logEvent({
            productID: banner.stat_product_id,
            firmID: banner.stat_firm_id,
            ctuID: banner.stat_ctu_id,
            objectType: "popupBanner",
            eventType: "popup",
            objectID: banner.id
        });
    });
}

export { initBannerSliders, showRandomBannerAsPopup };