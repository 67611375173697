﻿import * as $ from "jquery";
import { ajaxSend } from "./global/fetch";
import { addEventDelegate } from "./global/dom";

var tumStats = {
	options: {
		url: {
			logEvent: "/statistics/logEvent"
		},
		urlReferrer: ""
	},
	init: function(options){
		tumStats.options = $.extend({}, tumStats.options, options);

		addEventDelegate(document, 'tu.shown', '[data-stat-log-on-shown]', element => {
			var event = {
				productID: element.getAttribute("data-stat-productid"),
				firmID: element.getAttribute("data-stat-firmid"),
				ctuID: element.getAttribute("data-stat-ctuid"),
				objectType: element.getAttribute("data-stat-objecttype"),
				eventType: element.getAttribute("data-stat-eventtype-shown"),
				objectID: element.getAttribute("data-stat-objectid"),
				additionalInfoJson: element.getAttribute("data-stat-json")
			};

			tumStats.logEvent(event);
		});

		$(document).on("click auxclick", ".tu-stat-logEvent", function(e) {
			let element = this;
			//auxclick ловит клики средней и правой кнопками мыши. Правый игнорируем
			if (e.which == 3) {
				return;
			}

			var href = "";
			if (e.which == 1 && $(element).is("a:not([target=_blank])")) {
				var _href = $(element).attr("href");
				if (_href && _href.indexOf("/") > -1) {
					e.preventDefault();
					href = _href;
				}
			}

			var event = {
				productID: $(element).attr("data-stat-productid"),
				firmID: $(element).attr("data-stat-firmid"),
				ctuID: $(element).attr("data-stat-ctuid"),
				objectType: $(element).attr("data-stat-objecttype"),
				eventType: $(element).attr("data-stat-eventtype"),
				objectID: $(element).attr("data-stat-objectid"),
				additionalInfoJson: $(element).attr("data-stat-json")
			};

			$(element).removeClass("tu-stat-logEvent");

			tumStats.logEvent(event, href);
		});
	},
	/** 
	 * Лог события в статистику
	 * @event объект с полями:
	 * @productID
	 * @firmID
	 * @ctuID
	 * @objectType объект статистики
	 * @eventType событие
	 * @additionalInfoJson json с доп. инфо
	 * @objectID id связанного объекта, например обзора
	 * @
	 * @redirectUrl если нужен редирект после отправки
	 */
	logEvent: function(event, redirectUrl) {
		event.urlReferrer = tumStats.options.urlReferrer;
		event.url = location.href;
		event.isAjax = true;
		ajaxSend({ url: tumStats.options.url.logEvent, data: event }).then(data => {
			if (!data.result) {
				console.log("statistics: logEvent error");
			}

			if (redirectUrl) {
				location.href = redirectUrl;
			}
		});
	},
	// initYoutubeEvents: function(){
	// 	window.onYouTubeIframeAPIReady = this._initYoutubeEvents.bind(this);

	// 	loadLib({
	// 		jsLink: 'https://www.youtube.com/iframe_api',
	// 		conditionObject: window.YT,
	// 		isCacheable: true
	// 	});
	// },
	// _initYoutubeEvents: function(){
	// 	let YT = window.YT;
	// 	let self = this;

	// 	$(".tu-youTubeFrame.tu-stat-logEvent").each(function(){
	// 		let video  = new YT.Player(this);
	// 		let logged = false;

	// 		let event = {
	// 			objectType: $(this).attr('data-stat-objecttype'),
	// 			eventType: $(this).attr('data-stat-eventtype'),
	// 			firmID: $(this).attr('data-stat-firmid'),
	// 			objectID: $(this).attr('data-stat-objectid')
	// 		};

	// 		video.addEventListener('onStateChange', function(e){
	// 			if (e.data == YT.PlayerState.PLAYING && !logged) {
	// 				logged = true;
	// 				self.logEvent(event);
	// 			}
	// 		});
	// 	});
	// }
};

export { tumStats };